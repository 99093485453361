<template>
    <div class="credit-card" :class="`card-${cardID}`">
        <h3 class="title mb-30 cgray f13">
            <a-row>
                <a-col :span="12"> Cartão de Crédito - Nº {{ cardID }} </a-col>
                <a-col class="a-right" :span="12">
                    <a-button
                        v-if="cardID > 1"
                        size="small"
                        type="danger"
                        shape="circle"
                        icon="delete"
                        @click="removeRow(cardID - 1)"
                    >
                    </a-button>
                </a-col>
            </a-row>
        </h3>

        <a-row :gutter="[20, 0]">
            <a-col :span="6">
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_total_value_${cardID}`
                            )
                                ? 'filled'
                                : ''
                        "
                        >Total Cartão de Crédito
                    </label>

                    <a-input
                        class="travel-input"
                        placeholder="Valor total Cartão de Crédito"
                        v-currency
                        @keyup="updateCreditCardTotal"
                        @blur="updateCreditCardTotal"
                        v-decorator="[
                            `payment_methods_credit_card_total_value_${cardID}`,
                            {
                                rules: [
                                    {
                                        required: dynamicRequiredField(),
                                        message: 'Preencha o valor.',
                                    },
                                    {
                                        validator: verifyValue,
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="8">
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_owner_${cardID}`
                            ) ||
                            form.getFieldValue(
                                `payment_methods_credit_card_owner_${cardID}`
                            ) == 0
                                ? 'filled'
                                : ''
                        "
                        >Proprietário do cartão</label
                    >
                    <a-select
                        class="travel-input select"
                        placeholder="Proprietário do cartão"
                        v-decorator="[
                            `payment_methods_credit_card_owner_${cardID}`,

                            {
                                rules: [
                                    {
                                        required: dynamicRequiredField(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(
                                item, index
                            ) of CreditCardAndThirdPartyOwners()"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="6">
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_machine_id_${cardID}`
                            ) ||
                            form.getFieldValue(
                                `payment_methods_credit_card_machine_id_${cardID}`
                            ) == 0
                                ? 'filled'
                                : ''
                        "
                        >Maquininha</label
                    >
                    <a-select
                        class="travel-input select"
                        placeholder="Maquininha"
                        allow-clear
                        @change="onChangeCardMachine"
                        v-decorator="[
                            `payment_methods_credit_card_machine_id_${cardID}`,

                            {
                                rules: [
                                    {
                                        required: dynamicRequiredField(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of cardMachines.list"
                            :key="index"
                            :value="item.id"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col
                v-show="
                    form.getFieldValue(
                        `payment_methods_credit_card_machine_id_${cardID}`
                    )
                "
                :span="4"
            >
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_installments_${cardID}`
                            )
                                ? 'filled'
                                : ''
                        "
                        >Parcelas</label
                    >

                    <a-select
                        class="travel-input select"
                        placeholder="Parcelas"
                        @change="calcInstallments"
                        v-decorator="[
                            `payment_methods_credit_card_installments_${cardID}`,

                            {
                                initialValue: 2,
                                rules: [
                                    {
                                        required: dynamicRequiredField(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of installments"
                            :key="index"
                            :value="item"
                        >
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col
                v-show="
                    form.getFieldValue(
                        `payment_methods_credit_card_installments_${cardID}`
                    )
                "
                :span="6"
            >
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_first_installment_value_${cardID}`
                            )
                                ? 'filled'
                                : ''
                        "
                        >Valor da 1ª parcela (R$)</label
                    >
                    <a-input
                        class="travel-input"
                        placeholder="Valor da 1ª parcela"
                        v-currency
                        v-decorator="[
                            `payment_methods_credit_card_first_installment_value_${cardID}`,
                            {
                                rules: [
                                    {
                                        required: dynamicRequiredField(),
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col
                v-show="
                    form.getFieldValue(
                        `payment_methods_credit_card_installments_${cardID}`
                    )
                "
                :span="5"
            >
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_installments_value_${cardID}`
                            )
                                ? 'filled'
                                : ''
                        "
                        >Demais parcelas (R$)</label
                    >
                    <a-input
                        class="travel-input"
                        v-currency
                        placeholder="Demais parcelas"
                        v-decorator="[
                            `payment_methods_credit_card_installments_value_${cardID}`,
                            {
                                rules: [
                                    {
                                        required: dynamicRequiredField(),
                                        message: 'Preencha todos números.',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col
                :class="$root.isAdmin() ? '' : 'none'"
                v-show="
                    form.getFieldValue(
                        `payment_methods_credit_card_installments_${cardID}`
                    )
                "
                :span="5"
            >
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_tax_value_${cardID}`
                            )
                                ? 'filled'
                                : ''
                        "
                        >Taxa PDQ (R$)</label
                    >
                    <a-input
                        class="travel-input"
                        v-currency
                        placeholder="Taxa PDQ (R$)"
                        :disabled="true"
                        v-decorator="[
                            `payment_methods_credit_card_tax_value_${cardID}`,
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col
                :class="$root.isAdmin() ? '' : 'none'"
                v-show="
                    form.getFieldValue(
                        `payment_methods_credit_card_installments_${cardID}`
                    )
                "
                :span="4"
            >
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_advance_fee_value_${cardID}`
                            )
                                ? 'filled'
                                : ''
                        "
                    >
                        Taxa Adm (R$)</label
                    >
                    <a-input
                        class="travel-input"
                        v-currency
                        placeholder="Taxa Adm"
                        :disabled="true"
                        v-decorator="[
                            `payment_methods_credit_card_advance_fee_value_${cardID}`,
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col
                v-show="
                    form.getFieldValue(
                        `payment_methods_credit_card_installments_${cardID}`
                    )
                "
                :span="4"
            >
                <a-form-item>
                    <label
                        :class="
                            form.getFieldValue(
                                `payment_methods_credit_card_total_tax_discount_${cardID}`
                            )
                                ? 'filled '
                                : ''
                        "
                        >Total Taxas (R$)</label
                    >
                    <a-input
                        class="travel-input red"
                        v-currency
                        placeholder="Total Taxas"
                        :disabled="true"
                        v-decorator="[
                            `payment_methods_credit_card_total_tax_discount_${cardID}`,
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <div style="clear: both" />

            <a-row
                v-if="
                    form.getFieldValue(
                        `payment_methods_credit_card_owner_${cardID}`
                    ) == 'credit-card-owner'
                "
                :gutter="[20, 0]"
                style="padding: 0 10px"
            >
                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_name`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Nome completo</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Nome completo"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_name`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_CPF`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >CPF</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="CPF"
                            v-mask="'###.###.###-##'"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_CPF`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_mobile_phone`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Celular</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Celular"
                            v-mask="'(##) # ####-####'"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_mobile_phone`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_phone`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Telefone fixo</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Telefone fixo"
                            v-mask="'(##) ####-####'"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_phone`,
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_CEP`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >CEP</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="CEP"
                            v-mask="'#####-###'"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_CEP`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                            @change="getLocation()"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                            <a-icon
                                v-if="cepLoading"
                                slot="suffix"
                                type="loading"
                            />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${this.cardID}_CEP`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Logradouro</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Logradouro"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_location_street`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_location_number`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Número</label
                        >
                        <a-input
                            type="number"
                            class="travel-input"
                            placeholder="Número"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_location_number`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_location_complement`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Complemento</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Complemento"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_location_complement`,
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_location_neighborhood`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Bairro</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Bairro"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_location_neighborhood`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_location_city`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Cidade</label
                        >
                        <a-input
                            class="travel-input"
                            placeholder="Cidade"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_location_city`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item>
                        <label
                            :class="
                                this.form.getFieldValue(
                                    `payment_methods_credit_card_owner_${cardID}_location_state`
                                )
                                    ? 'filled'
                                    : ''
                            "
                            >Estado</label
                        >
                        <a-select
                            class="travel-input"
                            show-search
                            placeholder="ES"
                            v-decorator="[
                                `payment_methods_credit_card_owner_${cardID}_location_state`,
                                {
                                    rules: [
                                        {
                                            required: dynamicRequiredField(),
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="(item, index) of states"
                                :key="index"
                                :value="item.initials"
                            >
                                {{ item.initials.toUpperCase() }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-row>

        <a-row :gutter="[20, 0]">
            <a-col :span="24">
                <div class="travel-credit-card">
                    <div class="card">
                        <div class="front">
                            <span class="chip"
                                ><img
                                    src="../../../assets/images/chip.png"
                                    alt="chip"
                            /></span>

                            <span class="flag" v-if="showCreditCardFields">
                                {{ GetCardType() }}
                            </span>
                            <span class="flag" v-if="!showCreditCardFields">
                                {{
                                    form.getFieldValue(
                                        `payment_methods_credit_card_masked_flag_${cardID}`
                                    )
                                }}
                            </span>
                            <span class="numbers">
                                {{
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_number_${cardID}`
                                    ) | defaultNumbers
                                }}
                            </span>
                            <span class="name">
                                <font class="txt">Nome impresso</font>
                                <font class="value">{{
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_name_${cardID}`
                                    )
                                }}</font>
                            </span>
                            <span class="expires">
                                <font class="txt">Expiração</font>
                                <font class="value"
                                    >{{
                                        form.getFieldValue(
                                            `payment_methods_credit_card_client_month_${cardID}`
                                        ) | defaultCardDate
                                    }}/{{
                                        form.getFieldValue(
                                            `payment_methods_credit_card_client_year_${cardID}`
                                        ) | defaultCardDate
                                    }}</font
                                >
                            </span>
                        </div>
                        <div class="back">
                            <span class="blackbar"></span>
                            <span
                                class="cvv"
                                v-show="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_have_cvv_${cardID}`
                                    )
                                "
                            >
                                <font class="txt">CVV</font>
                                <font class="value">{{
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_cvv_${cardID}`
                                    ) | cvvAsterisk
                                }}</font>
                            </span>
                        </div>
                    </div>
                </div>
            </a-col>
        </a-row>

        <a-row
            class="credit-card-data mb-50"
            v-if="!showCreditCardFields"
            :gutter="[20, 0]"
        >
            <a-col :span="24">
                <a-row :gutter="[20, 0]">
                    <a-col class="card-col" :span="5">
                        <label>Nome impresso no cartão</label>
                        <div class="value">
                            {{
                                this.contract.meta[
                                    `payment_methods_credit_card_masked_name_${cardID}`
                                ]
                            }}
                        </div>
                    </a-col>
                    <a-col class="card-col" :span="6">
                        <label>Numero do cartão</label>
                        <div class="value">
                            {{
                                this.contract.meta[
                                    `payment_methods_credit_card_masked_number_${cardID}`
                                ]
                            }}
                        </div>
                    </a-col>
                    <a-col class="card-col" :span="4">
                        <label>Expiração do cartão</label>
                        <div class="value">
                            {{
                                this.contract.meta[
                                    `payment_methods_credit_card_masked_month_${cardID}`
                                ]
                            }}/{{
                                this.contract.meta[
                                    `payment_methods_credit_card_masked_year_${cardID}`
                                ]
                            }}
                        </div>
                    </a-col>
                    <a-col class="card-col" :span="4">
                        <label>Bandeira</label>
                        <div class="value">
                            {{
                                this.contract.meta[
                                    `payment_methods_credit_card_masked_flag_${cardID}`
                                ]
                            }}
                        </div>
                    </a-col>
                    <a-col class="card-col" :span="3">
                        <label>CVV</label>
                        <div class="value">
                            {{
                                this.contract.meta[
                                    `payment_methods_credit_card_masked_cvv_${cardID}`
                                ]
                            }}
                        </div>
                    </a-col>
                    <a-col class="card-col view" :span="2">
                        <a-tooltip placement="top" title="Ver dados do cartão">
                            <a-button
                                type="primary"
                                @click="viewCreditCardData"
                                shape="circle"
                                icon="eye"
                            />
                        </a-tooltip>
                        <a-modal
                            title="Verificação de usuário"
                            :visible="showPasswordModal"
                            :footer="null"
                            class="travel-modal"
                            @cancel="handleCancel"
                            width="400px"
                        >
                            <a-row :gutter="[20, 0]">
                                <a-col :span="24">
                                    <a-input
                                        class="travel-input"
                                        size="large"
                                        v-model="userPassword"
                                        type="password"
                                        placeholder="Insira sua senha"
                                    >
                                        <a-icon
                                            slot="prefix"
                                            type="lock"
                                            style="color: rgba(0, 0, 0, 0.25)"
                                        />
                                    </a-input>
                                </a-col>

                                <a-col :span="24">
                                    <input
                                        name="email"
                                        style="
                                            color: #fff;
                                            opacity: 0;
                                            pointer-events: none;
                                            height: 0;
                                            padding: 0;
                                            border: 0;
                                        "
                                        placeholder="Fake email fix"
                                    />
                                    <a-input
                                        class="travel-input"
                                        size="large"
                                        v-model="authCode"
                                        placeholder="Código de autorização"
                                    >
                                        <a-icon
                                            slot="prefix"
                                            type="idcard"
                                            style="color: rgba(0, 0, 0, 0.25)"
                                        />
                                    </a-input>
                                </a-col>
                                <a-col class="a-right" :span="24">
                                    <a-button
                                        @click="getCardData"
                                        class="mt-10"
                                        type="primary"
                                        :loading="getCardDataLoading"
                                    >
                                        Ver dados
                                    </a-button>
                                </a-col>
                            </a-row>
                        </a-modal>
                    </a-col>
                </a-row>
            </a-col></a-row
        >

        <a-row v-if="showCreditCardFields" :gutter="[20, 0]">
            <a-col :span="24">
                <a-row :gutter="[20, 0]">
                    <a-col :span="12">
                        <a-form-item>
                            <label
                                :class="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_name_${cardID}`
                                    )
                                        ? 'filled'
                                        : ''
                                "
                                >Nome impresso no cartão</label
                            >
                            <a-input
                                class="travel-input"
                                placeholder="Nome impresso no cartão"
                                v-decorator="[
                                    `payment_methods_credit_card_client_name_${cardID}`,
                                    {
                                        rules: [
                                            {
                                                required:
                                                    dynamicRequiredField(),
                                                message:
                                                    'Preencha todos números.',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                        </a-form-item>

                        <a-form-item style="display: none">
                            <a-input
                                placeholder="Bandeira"
                                v-decorator="[
                                    `payment_methods_credit_card_client_flag_${cardID}`,
                                ]"
                            >
                            </a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item>
                            <label
                                :class="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_number_${cardID}`
                                    )
                                        ? 'filled'
                                        : ''
                                "
                                >Numero do cartão</label
                            >
                            <a-input
                                class="travel-input"
                                placeholder="Numero do cartão"
                                v-mask="'#### #### #### ####'"
                                @change="setCardType"
                                v-decorator="[
                                    `payment_methods_credit_card_client_number_${cardID}`,
                                    {
                                        rules: [
                                            {
                                                required:
                                                    dynamicRequiredField(),
                                                message:
                                                    'Preencha todos números.',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                        </a-form-item>
                    </a-col>

                    <a-col :span="4">
                        <a-form-item>
                            <label
                                :class="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_month_${cardID}`
                                    )
                                        ? 'filled'
                                        : ''
                                "
                                >Mês de Expiração</label
                            >
                            <a-select
                                class="travel-input select"
                                placeholder="Mês de Expiração"
                                v-decorator="[
                                    `payment_methods_credit_card_client_month_${cardID}`,
                                    {
                                        rules: [
                                            {
                                                required:
                                                    dynamicRequiredField(),
                                                message:
                                                    'Preencha todos números.',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-select-option
                                    v-for="(item, index) of cardMonths"
                                    :key="index"
                                    :value="item"
                                >
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="4">
                        <a-form-item>
                            <label
                                :class="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_year_${cardID}`
                                    )
                                        ? 'filled'
                                        : ''
                                "
                                >Ano de Expiração</label
                            >
                            <a-select
                                class="travel-input select"
                                placeholder="Ano"
                                v-decorator="[
                                    `payment_methods_credit_card_client_year_${cardID}`,
                                    {
                                        rules: [
                                            {
                                                required:
                                                    dynamicRequiredField(),
                                                message:
                                                    'Preencha todos números.',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-select-option
                                    v-for="(item, index) of cardYears"
                                    :key="index"
                                    :value="item"
                                >
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="5">
                        <a-form-item>
                            <a-switch
                                size="small"
                                :default-checked="
                                    checkCreditCardCVV(
                                        contract.meta[
                                            `payment_methods_credit_card_have_cvv_${this.cardID}`
                                        ]
                                    )
                                "
                                v-decorator="[
                                    `payment_methods_credit_card_have_cvv_${cardID}`,
                                ]"
                            />
                            <font
                                class="label ml-10"
                                style="font-size: 10px; font-weight: 700"
                                >Cliente tem CVV</font
                            >
                        </a-form-item>
                    </a-col>
                    <a-col
                        :span="4"
                        v-if="
                            form.getFieldValue(
                                `payment_methods_credit_card_have_cvv_${cardID}`
                            ) ||
                            contract.meta[
                                `payment_methods_credit_card_have_cvv_${this.cardID}`
                            ]
                        "
                    >
                        <a-form-item v-if="showCreditCardFields">
                            <label
                                :class="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_client_cvv_${cardID}`
                                    )
                                        ? 'filled'
                                        : ''
                                "
                                >CVV</label
                            >
                            <a-input
                                type="number"
                                class="travel-input"
                                placeholder="CVV"
                                v-mask="'####'"
                                v-decorator="[
                                    `payment_methods_credit_card_client_cvv_${cardID}`,
                                    {
                                        rules: [
                                            {
                                                required:
                                                    dynamicRequiredField(),
                                                message:
                                                    'Preencha todos números.',
                                            },
                                        ],
                                    },
                                ]"
                            >
                                <a-icon slot="prefix" type="field-svg" />
                            </a-input>
                        </a-form-item>
                    </a-col>

                    <a-col
                        v-if="
                            form.getFieldValue(
                                `payment_methods_credit_card_masked_name_${cardID}`
                            )
                        "
                        :span="2"
                    >
                        <a-form-item style="display: none">
                            <a-switch
                                size="small"
                                :default-checked="
                                    contract.meta[
                                        `payment_methods_credit_card_is_visible_${this.cardID}`
                                    ]
                                        ? false
                                        : false
                                "
                                v-decorator="[
                                    `payment_methods_credit_card_is_visible_${cardID}`,
                                ]"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col
                        :span="24"
                        v-if="
                            !form.getFieldValue(
                                `payment_methods_credit_card_have_cvv_${cardID}`
                            )
                        "
                    >
                        <a-form-item class="textarea">
                            <label
                                :class="
                                    form.getFieldValue(
                                        `payment_methods_credit_card_obs_${cardID}`
                                    )
                                        ? 'filled'
                                        : ''
                                "
                                >Motivo de não ter CVV</label
                            >

                            <a-input
                                class="travel-textarea"
                                placeholder="Motivo de não ter CVV"
                                type="textarea"
                                v-decorator="[
                                    `payment_methods_credit_card_obs_${cardID}`,
                                    {
                                        rules: [
                                            {
                                                required:
                                                    dynamicRequiredField(),
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]"
                            >
                            </a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import axios from "axios";
import { parse } from "vue-currency-input";
import states from "@/json/states.json";
import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";

export default {
    name: "CreditCardForm",
    mixins: [cardMachinesMixins],
    props: {
        contract: Object,
        tempContract: Object,
        form: Object,
        customerForm: Object,
        cardID: Number,
        totalCards: Number,
        payment_methods: Array,
        reservation_total_price: Number,
        verify_total: Boolean,
    },
    data() {
        return {
            showPasswordModal: false,
            activeTab: null,
            cardType: "",
            authCode: "",
            userPassword: null,
            showCreditCardFields: true,
            getCardDataLoading: false,
            cardMachineTaxesList: [],
            cardMachineAdvanceFeeList: [],
            totalValue: {
                status: false,
                total: 0,
            },
            states,
            banks: [
                {
                    name: "Banco Bradesco",
                    number: 237,
                    agency: "1246",
                    account: "15600-0",
                },
                {
                    name: "Banco Safra S.A",
                    number: 422,
                    agency: "0023",
                    account: "395014-1",
                },
                {
                    name: "Banco Cooperativo do Brasil",
                    number: 756,
                    agency: "3166",
                    account: "50340-1",
                },
            ],
            installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            cardMonths: [
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
            ],
            cardYears: [
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
                "2027",
                "2028",
                "2029",
                "2030",
                "2031",
                "2032",
                "2033",
                "2034",
                "2035",
                "2036",
                "2037",
                "2038",
                "2039",
                "2040",
            ],
        };
    },
    filters: {
        formatPrice: function (price) {
            return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
        },
        formatNumber: function (number) {
            return `${number}`;
        },
        cvvAsterisk: function (cvv) {
            let asterisk = "";
            if (cvv) {
                for (let i = 0; i < cvv.length; i++) {
                    asterisk += "*";
                }
            } else {
                asterisk = "---";
            }

            return `${asterisk}`;
        },
        defaultNumbers: function (numbers) {
            if (numbers) {
                numbers;
            } else {
                numbers = "---- ---- ---- ----";
            }

            return `${numbers}`;
        },
        defaultCardDate: function (date) {
            if (date) {
                if (date.length == 4) {
                    date = date.slice(-2);
                } else {
                    date;
                }
            } else {
                date = "--";
            }
            return `${date}`;
        },
        protectData: function (data) {
            let asterisk = "";
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    asterisk += "*";
                }
            } else {
                asterisk = "---";
            }

            return `${asterisk}`;
        },
    },
    created() {
        this.cardMachines.filters.status.selected = "Ativo";
        this.getCardMachines();

        setTimeout(() => {
            this.form.setFieldsValue({
                [`payment_methods_credit_card_owner_${this.cardID}`]:
                    this.contract.meta[
                        `payment_methods_credit_card_owner_${this.cardID}`
                    ] != undefined
                        ? `${
                              this.contract.meta[
                                  `payment_methods_credit_card_owner_${this.cardID}`
                              ]
                          }`
                        : undefined,
            });
        }, 250);

        setTimeout(() => {
            // VERIFICA SE EXISTE CAMPO DE CARTÂO DE CREDITO
            this.showCreditCardFields =
                this.tempContract[
                    `payment_methods_credit_card_masked_name_${this.cardID}`
                ] == undefined &&
                this.tempContract[
                    `payment_methods_credit_card_masked_number_${this.cardID}`
                ] == undefined &&
                this.tempContract[
                    `payment_methods_credit_card_masked_month_${this.cardID}`
                ] == undefined &&
                this.tempContract[
                    `payment_methods_credit_card_masked_year_${this.cardID}`
                ] == undefined &&
                this.tempContract[
                    `payment_methods_credit_card_masked_cvv_${this.cardID}`
                ] == undefined &&
                this.tempContract[
                    `payment_methods_credit_card_masked_flag_${this.cardID}`
                ] == undefined
                    ? true
                    : false;

            // PREENCHE OS CAMPOS DE CARTÂO DE CREDITO
            this.form.setFieldsValue({
                [`payment_methods_credit_card_total_value_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_total_value_${this.cardID}`
                    ],
                [`payment_methods_credit_card_total_with_tax_percentage_discount_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_total_with_tax_percentage_discount_${this.cardID}`
                    ],
                [`payment_methods_credit_card_total_tax_discount_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_total_tax_discount_${this.cardID}`
                    ],
                [`payment_methods_credit_card_machine_id_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_machine_id_${this.cardID}`
                    ],
                [`payment_methods_credit_card_installments_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_installments_${this.cardID}`
                    ],
                [`payment_methods_credit_card_first_installment_value_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_first_installment_value_${this.cardID}`
                    ],
                [`payment_methods_credit_card_installments_value_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_installments_value_${this.cardID}`
                    ],
                [`payment_methods_credit_card_tax_value_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_tax_value_${this.cardID}`
                    ],

                [`payment_methods_credit_card_masked_name_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_masked_name_${this.cardID}`
                    ],
                [`payment_methods_credit_card_masked_number_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_masked_number_${this.cardID}`
                    ],

                [`payment_methods_credit_card_masked_flag_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_masked_flag_${this.cardID}`
                    ],

                [`payment_methods_credit_card_masked_month_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_masked_month_${this.cardID}`
                    ],
                [`payment_methods_credit_card_masked_year_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_masked_year_${this.cardID}`
                    ],
                [`payment_methods_credit_card_masked_cvv_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_masked_cvv_${this.cardID}`
                    ],

                [`payment_methods_credit_card_obs_${this.cardID}`]:
                    this.tempContract[
                        `payment_methods_credit_card_obs_${this.cardID}`
                    ],
                [`payment_methods_credit_card_have_cvv_${this.cardID}`]:
                    this.checkCreditCardCVV(
                        this.tempContract[
                            `payment_methods_credit_card_have_cvv_${this.cardID}`
                        ]
                    ),
                [`payment_methods_credit_card_owner_${this.cardID}_name`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_name`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_CPF`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_CPF`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_mobile_phone`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_mobile_phone`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_phone`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_phone`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_CEP`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_CEP`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_location_street`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_location_street`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_location_number`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_location_number`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_location_complement`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_location_complement`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_location_neighborhood`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_location_neighborhood`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_location_city`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_location_city`
                    ],
                [`payment_methods_credit_card_owner_${this.cardID}_location_state`]:
                    this.tempContract[
                        `payment_methods_credit_card_owner_${this.cardID}_location_state`
                    ],
            });

            this.updateCreditCardTotal(
                this.tempContract[
                    `payment_methods_credit_card_total_value_${this.cardID}`
                ]
            );
        }, 500);
    },
    methods: {
        onChangeCardMachine(id) {


            this.form.setFieldsValue({
                [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]:
                    "",
            });

            this.form.setFieldsValue({
                [`payment_methods_credit_card_tax_value_${this.cardID}`]: "",
            });

            this.form.setFieldsValue({
                [`payment_methods_credit_card_total_tax_discount_${this.cardID}`]:
                    "",
            });

            let tax = this.cardMachines.list.filter((e) => {
                return e.id == id;
            });

            if (tax[0] != undefined) {
                this.form.setFieldsValue({
                    [`payment_methods_credit_card_taxes_range_${this.cardID}`]:
                        tax[0].taxes_range,
                });

                this.form.setFieldsValue({
                    [`payment_methods_credit_card_advance_fee_range_${this.cardID}`]:
                        tax[0].advance_fee,
                });
            } else {
                this.form.setFieldsValue({
                    [`payment_methods_credit_card_taxes_range_${this.cardID}`]:
                        "[]",
                });

                this.form.setFieldsValue({
                    [`payment_methods_credit_card_advance_fee_range_${this.cardID}`]:
                        "[]",
                });
                setTimeout(() => {
                    this.tempContract[
                        `payment_methods_credit_card_tax_id_${this.cardID}`
                    ] = "";
                }, 100);
            }
        },
        checkCreditCardCVV(haveCvv) {
            let flag = false;
            if (haveCvv == undefined) {
                flag = true;
            }

            if (haveCvv) {
                flag = true;
            }

            return flag;
        },
        CreditCardAndThirdPartyOwners() {
            let theOwners = [];

            theOwners.push({
                id: -1,
                name: "INFORMAR DADOS DO PROPRIETÁRIO",
                value: "credit-card-owner",
            });

            if (
                this.customerForm.getFieldValue("person_type") ==
                "Pessoa Física"
            ) {
                theOwners.push({
                    id: 0,
                    name:
                        this.customerForm.getFieldValue("first_name") !=
                        undefined
                            ? "CONTRATANTE: " +
                              this.customerForm
                                  .getFieldValue("first_name")
                                  .toUpperCase() +
                              " " +
                              this.customerForm
                                  .getFieldValue("last_name")
                                  .toUpperCase()
                            : "",
                    value: "client",
                });
            } else {
                theOwners.push({
                    id: 0,
                    name:
                        this.customerForm.getFieldValue("trading_name") !=
                        undefined
                            ? "CONTRATANTE: " +
                              this.customerForm
                                  .getFieldValue("trading_name")
                                  .toUpperCase()
                            : "",
                    value: "client",
                });
            }

            return theOwners;
        },
        formatPrice(price) {
            return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
        },
        updateCreditCardTotal(val) {

            if (val != undefined) {

                this.$emit("creditCardTotal", parse(val.target.value));
                this.$emit("verifyTotal", parse(val.target.value));
            } else {
                val = 0;
                this.$emit("creditCardTotal", parse(val));
                this.$emit("verifyTotal", parse(val));
            }
        },
        addRow(index) {
            this.$emit("addCreditCard", index);
        },
        removeRow(index) {
            this.$emit("removeCreditCard", index);
        },
        protectData(data) {
            let asterisk = "";
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    asterisk += "x";
                }
            } else {
                asterisk = "";
            }

            return `${asterisk}`;
        },
        crediCardFields() {
            let name = this.form.getFieldValue(
                `credit_card_data_${this.cardID}[cardName]`
            );
            let number = this.form.getFieldValue(
                `credit_card_data_${this.cardID}[cardNumber]`
            );
            let month = this.form.getFieldValue(
                `credit_card_data_${this.cardID}[cardMonth]`
            );
            let year = this.form.getFieldValue(
                `credit_card_data_${this.cardID}[cardYear]`
            );
            let cvv = this.form.getFieldValue(
                `credit_card_data_${this.cardID}[cardCvv]`
            );

            if (!name) {
                name = "";
            }

            if (!number) {
                number = "";
            }

            if (!month) {
                month = "";
            }

            if (!year) {
                year = "";
            }

            if (!cvv) {
                cvv = "";
            }

            return {
                cardName: name,
                cardNumber: number,
                cardMonth: month,
                cardYear: year,
                cardCvv: cvv,
            };
        },
        getLocation() {
            setTimeout(() => {
                if (
                    this.form.getFieldValue(
                        `payment_methods_credit_card_owner_${this.cardID}_CEP`
                    )
                ) {
                    if (
                        this.form
                            .getFieldValue(
                                `payment_methods_credit_card_owner_${this.cardID}_CEP`
                            )
                            .replace("-", "").length == 8
                    ) {
                        this.cepLoading = true;
                        axios
                            .get(
                                "https://viacep.com.br/ws/" +
                                    this.form.getFieldValue(
                                        `payment_methods_credit_card_owner_${this.cardID}_CEP`
                                    ) +
                                    "/json/"
                            )
                            .then(({ data }) => {
                                this.cepLoading = false;
                                this.form.setFieldsValue({
                                    [`payment_methods_credit_card_owner_${this.cardID}_location_street`]:
                                        data.logradouro.toUpperCase(),
                                });

                                this.form.setFieldsValue({
                                    [`payment_methods_credit_card_owner_${this.cardID}_location_state`]:
                                        data.uf,
                                });

                                this.form.setFieldsValue({
                                    [`payment_methods_credit_card_owner_${this.cardID}_location_city`]:
                                        data.localidade.toUpperCase(),
                                });

                                this.form.setFieldsValue({
                                    [`payment_methods_credit_card_owner_${this.cardID}_location_neighborhood`]:
                                        data.bairro.toUpperCase(),
                                });
                            })
                            .catch(({ response }) => {
                                response;
                                this.$message.error(
                                    "Houve um problema ao recuperar a localização."
                                );
                                this.cepLoading = false;
                            });
                    }
                }
            }, 110);
        },
        calcInstallments(installments) {
            let creditCardValue = this.form.getFieldValue(
                `payment_methods_credit_card_total_value_${this.cardID}`
            );

            if (creditCardValue) {
                let monthlyInstallments = [];

                creditCardValue = parse(creditCardValue);

                let taxesRange =
                    this.tempContract[
                        `payment_methods_credit_card_taxes_range_${this.cardID}`
                    ];

                let advanceFeeRange =
                    this.tempContract[
                        `payment_methods_credit_card_advance_fee_range_${this.cardID}`
                    ];

                let selectedTax = "";
                let selectedAdvanceFee = "";
                let totalAdvanceFee = 0;
                let totalTax = 0;

                if (advanceFeeRange && advanceFeeRange != "[]") {
                    advanceFeeRange = JSON.parse(advanceFeeRange);

                    if (advanceFeeRange.length > 0) {
                        selectedAdvanceFee = advanceFeeRange.filter((e) => {
                            return e.id == installments;
                        });


                        let totalPercentage = 0;
                        let thePercentage = selectedAdvanceFee[0].value
                            .replace("%", "")
                            .replace(",", ".");
                        totalPercentage = parseFloat(thePercentage);

                        let theTaxPercentageDiscount = 0;

                        theTaxPercentageDiscount =
                            (totalPercentage / 100) * creditCardValue;


                        this.form.setFieldsValue({
                            [`payment_methods_credit_card_advance_fee_percentage_${this.cardID}`]:
                                totalPercentage,
                        });

                        this.form.setFieldsValue({
                            [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]:
                                theTaxPercentageDiscount
                                    .toFixed(2)
                                    .replace(".", ","),
                        });

                        totalAdvanceFee = theTaxPercentageDiscount;
                    }
                } else {
                    selectedAdvanceFee = "";
                    advanceFeeRange = [];
                    totalAdvanceFee = 0;



                    this.form.setFieldsValue({
                        [`payment_methods_credit_card_advance_fee_percentage_${this.cardID}`]: 0,
                    });

                    this.form.setFieldsValue({
                        [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]: 0,
                    });
                }

                if (taxesRange && taxesRange != "[]") {
                    taxesRange = JSON.parse(taxesRange);

                    if (taxesRange.length > 0) {
                        selectedTax = taxesRange.filter((e) => {
                            return e.id == installments;
                        });

                        let totalPercentage = 0;
                        let thePercentage = selectedTax[0].value
                            .replace("%", "")
                            .replace(",", ".");
                        totalPercentage = parseFloat(thePercentage);

                        let theTaxPercentageDiscount = 0;

                        theTaxPercentageDiscount =
                            (totalPercentage / 100) * creditCardValue;

                        this.form.setFieldsValue({
                            [`payment_methods_credit_card_tax_percentage_${this.cardID}`]:
                                totalPercentage,
                        });

                        this.form.setFieldsValue({
                            [`payment_methods_credit_card_tax_value_${this.cardID}`]:
                                theTaxPercentageDiscount
                                    .toFixed(2)
                                    .replace(".", ","),
                        });



                        totalTax = theTaxPercentageDiscount;
                    }
                } else {
                    selectedTax = "";
                    taxesRange = [];
                    totalTax = 0;

                    this.form.setFieldsValue({
                        [`payment_methods_credit_card_tax_percentage_${this.cardID}`]: 0,
                    });

                    this.form.setFieldsValue({
                        [`payment_methods_credit_card_tax_value_${this.cardID}`]: 0,
                    });
                }

                this.form.setFieldsValue({
                    [`payment_methods_credit_card_total_with_tax_percentage_discount_${this.cardID}`]:
                        (creditCardValue - (totalTax + totalAdvanceFee))
                            .toFixed(2)
                            .replace(".", ","),
                });

                this.form.setFieldsValue({
                    [`payment_methods_credit_card_total_tax_discount_${this.cardID}`]:
                        (totalTax + totalAdvanceFee)
                            .toFixed(2)
                            .replace(".", ","),
                });



                for (let i = 1; i < installments + 1; i++) {
                    monthlyInstallments.push(creditCardValue / installments);
                }

                this.form.setFieldsValue({
                    [`payment_methods_credit_card_first_installment_value_${this.cardID}`]:
                        this.formatPrice(monthlyInstallments[0]),
                });

                this.form.setFieldsValue({
                    [`payment_methods_credit_card_installments_value_${this.cardID}`]:
                        this.formatPrice(monthlyInstallments[0]),
                });
            }
        },
        setCardType() {
            setTimeout(() => {
                this.form.setFieldsValue({
                    [`payment_methods_credit_card_client_flag_${this.cardID}`]:
                        this.GetCardType(),
                });
            }, 50);
        },
        GetCardType() {
            let number = this.form.getFieldValue(
                `payment_methods_credit_card_client_number_${this.cardID}`
            );



            if (number) {
                let theFlag = "";

                number = number.replace(/\s+/g, "");

                // visa
                var re = new RegExp("^4");
                if (number.match(re) != null) {
                    theFlag = "Visa";
                }

                // Mastercard
                // Updated for Mastercard 2017 BINs expansion
                if (
                    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
                        number
                    )
                ) {
                    theFlag = "Mastercard";
                }

                // AMEX
                re = new RegExp("^3[47]");
                if (number.match(re) != null) {
                    theFlag = "AMEX";
                }

                // Discover
                re = new RegExp(
                    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
                );
                if (number.match(re) != null) {
                    theFlag = "Discover";
                }

                // Diners
                re = new RegExp("^36");
                if (number.match(re) != null) {
                    theFlag = "Diners";
                }

                // Diners - Carte Blanche
                re = new RegExp("^30[0-5]");
                if (number.match(re) != null) {
                    theFlag = "Diners - Carte Blanche";
                }

                // JCB
                re = new RegExp("^35(2[89]|[3-8][0-9])");
                if (number.match(re) != null) {
                    theFlag = "JCB";
                }

                // Visa Electron
                re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
                if (number.match(re) != null) {
                    theFlag = "Visa Electron";
                }

                // Hipercard
                re = new RegExp("^606282|^637095|^637599|^637568");
                if (number.match(re) != null) {
                    theFlag = "Hipercard";
                }

                // Elo
                re = new RegExp(
                    "^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))"
                );
                if (number.match(re) != null) {
                    theFlag = "Elo";
                }

                return theFlag;
            }

            return "";
        },
        handleCancel(e) {
            e;
            this.showPasswordModal = false;
        },
        viewCreditCardData() {
            this.showPasswordModal = true;

        },
        dynamicRequiredField() {
            let flag = this.form.getFieldValue("payment_methods");

            if (flag != undefined) {
                flag = this.form
                    .getFieldValue("payment_methods")
                    .includes("credit-card");
            } else {
                flag = false;
            }

            return flag;
        },
        dynamicCVVRequiredField() {

            let flag = this.form.getFieldValue(
                `payment_methods_credit_card_have_cvv_${this.cardID}`
            );

            if (flag != undefined) {
                flag = true;
            } else {
                flag = false;
            }

            return flag;
        },
        async verifyValue(rule, value, callback) {
            rule;
            let theValue = parseFloat(value);
            if (isNaN(theValue) || theValue == undefined) {
                theValue = 0;
            } else {
                if (parseFloat(value) > this.reservation_total_price) {
                    callback(
                        `O valor preenchido ultrapassa o total da reserva (R$ ${parseFloat(
                            this.reservation_total_price
                        )}).`
                    );
                } else {
                    callback();
                }
            }
        },
        getCardData() {
            // INSERIU A SENHA
            if (this.userPassword && this.authCode) {
                this.getCardDataLoading = true;
                this.$http
                    .post("/contract/read_card", {
                        email: this.$store.state.userData.email,
                        auth_code: this.authCode,
                        password: this.userPassword,
                        contract_id: this.contract.id,
                        card_id: this.cardID,
                        creted_by: this.$store.state.userData.id,
                        modified_by: {
                            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
                            id: this.$store.state.userData.id,
                        },
                    })
                    .then(({ data }) => {
                        this.$message.success(data.message);
                        this.showCreditCardFields = data.show_fields;

                        setTimeout(() => {
                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_client_name_${this.cardID}`]:
                                    data.card[
                                        `payment_methods_credit_card_client_name_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_client_number_${this.cardID}`]:
                                    data.card[
                                        `payment_methods_credit_card_client_number_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_client_flag_${this.cardID}`]:
                                    data.card[
                                        `payment_methods_credit_card_client_flag_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_client_month_${this.cardID}`]:
                                    data.card[
                                        `payment_methods_credit_card_client_month_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_client_year_${this.cardID}`]:
                                    data.card[
                                        `payment_methods_credit_card_client_year_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_client_cvv_${this.cardID}`]:
                                    data.card[
                                        `payment_methods_credit_card_client_cvv_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_have_cvv_${this.cardID}`]:
                                    this.tempContract[
                                        `payment_methods_credit_card_have_cvv_${this.cardID}`
                                    ],
                            });

                            this.form.setFieldsValue({
                                [`payment_methods_credit_card_obs_${this.cardID}`]:
                                    this.tempContract[
                                        `payment_methods_credit_card_obs_${this.cardID}`
                                    ],
                            });
                        }, 500);
                        this.getCardDataLoading = false;
                        this.authCode = "";
                    })
                    .catch(({ response }) => {
                        this.$message.error(response.data.message);
                        this.getCardDataLoading = false;
                    });
            } else {
                this.$message.error(
                    "Insira sua senha e o código de autorização."
                );
            }
        },
    },
};
</script>
